<template>
  <div>
    <div class="dropdown text-sm">
      <div class="selected block py-3 pl-2 text-neutral-700" :class="[disabled ? 'cursor-not-allowed bg-white opacity-50' : 'cursor-pointer', { open: open }]" @click="toggleDropdown()">
        <template>
          <img :src="selected.image" class="h-5 w-5" alt="" />
        </template>
        <span class="drop-arrow absolute right-2 top-1/2 transform -translate-y-1/2 leading-0" :class="{ expanded: open }">
          <Chevron direction="down" width="14" height="14" />
        </span>
      </div>
      <div class="content py-2" :class="{ selectHide: !open }">
        <template v-if="dropdownOptions.length > 0">
          <div v-for="(option, i) of dropdownOptions" :key="i" class="bg-white px-3 py-2 leading-tight text-neutral-700 hover:bg-neutral-300 cursor-pointer text-white" @click="selectItem(option)">
            <img :src="option.image" class="h-5 w-5" alt="" />
          </div>
        </template>
        <div class="text-sm text-white text-center" v-else>- Data is not available -</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Chevron'),
    Search: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Search')
  },
  props: {
    enableSearch: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Pilih'
    },
    optionLabel: {
      type: String,
      default: null
    },
    defaultValue: {
      required: false,
      default: null
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    capitalize: {
      type: Boolean,
      default: false
    },
    isReset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOptions: {
        value: 'left',
        image: require('@/assets/images/align-left.png')
      },
      dropdownOptions: [
        {
          value: 'left',
          image: require('@/assets/images/align-left.png')
        },
        {
          value: 'center',
          image: require('@/assets/images/align-center.png')
        }
      ],
      selected: this.defaultValue ? this.defaultValue : null,
      open: false,
      placeholderText: this.defaultValue ? null : this.placeholder,
      searchKeyword: ''
    }
  },
  watch: {
    defaultValue() {
      this.placeholderText = this.defaultValue ? null : this.placeholder
      this.selected = this.defaultValue ? this.defaultValue : null
    },
    options() {
      this.placeholderText = this.defaultValue ? null : this.placeholder
      this.selected = this.defaultValue ? this.defaultValue : null
    },
    isReset() {
      this.selected = this.defaultValue ? this.defaultValue : null
      this.placeholderText = this.placeholder
    }
  },
  mounted() {
    this.$emit('input', this.selected)
  },
  methods: {
    selectItem(option) {
      this.toggleDropdown()
      this.selected = option
      this.$emit('selectItem', option)
      this.$emit('input', option)
    },
    toggleDropdown() {
      if (this.disabled) {
        return
      }
      this.open = !this.open
    },
    search() {
      this.$emit('search', this.searchKeyword)
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
}

.dropdown .selected {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ced5de;
  position: relative;
  // cursor: pointer;
  user-select: none;
  z-index: 1;
  .drop-arrow {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.59, 1.39, 0.37, 1.01);
  }
  .expanded {
    transform: rotateZ(180deg) translateY(7px);
  }
  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.selectHide {
  display: none;
}
.placeholder {
  color: #9ca3af;
}
.content {
  max-height: 250px;
  overflow-y: auto;
  width: 100%;
  position: absolute;
  z-index: 10;
  margin-top: -1px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.p-3 {
  padding: 12px !important;
}
.option-label {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
</style>
